import { Box, BoxProps, Text, VStack } from "@efishery/onefish";

interface IRibbonFlagProps {
  category?: "discount" | "physical-prize";
  wrapperProps?: {
    outer?: BoxProps;
    inner?: BoxProps;
  };
}

export function RibbonFlag({ category, wrapperProps }: IRibbonFlagProps) {
  const ribbonColor = () => {
    switch (category) {
      case "discount":
        return "#D44140";
      case "physical-prize":
        return "#F48324";
      default:
        return "#D44140";
    }
  };
  const ribbonLabel = () => {
    switch (category) {
      case "discount":
        return "Potongan Harga";
      case "physical-prize":
        return "Hadiah Spesial";
      default:
        return "Potongan Harga";
    }
  };
  return (
    <Box position="absolute" {...wrapperProps?.outer}>
      <VStack pos="relative">
        <Box
          pl="10px"
          pr="8px"
          py="3px"
          bgColor={ribbonColor()}
          borderTopEndRadius="5px"
          _before={{
            content: '""',
            width: "25px",
            height: "25.2px",
            background: ribbonColor(),
            position: "absolute",
            top: "0px",
            left: "-15px",
            clipPath: `polygon(
                100% 0,
                100% 100%,
                0 100%,
                50% 50%,
                0.00% 0.00% )
            `,
          }}
          _after={{
            content: '""',
            height: "0",
            width: "0",
            display: "block",
            position: "absolute",
            bottom: "-10px",
            right: "-0.2px",
            borderTop: `10px solid ${ribbonColor()}`,
            borderRight: "10px solid transparent",
          }}
          {...wrapperProps?.inner}
        >
          <Text fontSize="12px" color="white" fontWeight="600">
            {ribbonLabel()}
          </Text>
        </Box>
      </VStack>
    </Box>
  );
}
